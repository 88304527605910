import { Button, Box, Container, Stack, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSetPassword } from 'api/auth';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { TextField } from 'component/base/TextField';
import { useTranslation, Trans } from 'react-i18next';
import { useQueryParams } from 'component/hooks/queryParams';
import passwordStrength from 'password-strength-calc';
import { useSnackbar } from 'component/hooks/useSnackbar';
import cookie from 'js-cookie';
import axios from 'axios';

const PREFIX = 'Login';

const classes = {
  container: `${PREFIX}Container`,
};

const StyledContainer = styled(Container)({
  [`&.${classes.container}`]: {
    display: 'flex',
    minHeight: '100vh',
    paddingBottom: '1.50rem',
    paddingTop: '1.50rem',
    '& > *': {
      marginBottom: 'auto',
      marginTop: 'auto',
      width: '100%',
    },
  },
});

interface FormValue {
  email: string;
  password: string;
  verifyPassword: string;
  token: string;
}

export default function ResetPassword() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useQueryParams();
  const token = params.get('token') ?? '';
  const email = params.get('email') ?? '';
  const userId = params.get('user_id') ?? '';

  const methods = useForm<FormValue>({
    defaultValues: {
      password: '',
      verifyPassword: '',
      token,
      email,
    },
    mode: 'onChange',
  });
  const { handleSubmit, watch } = methods;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const setPassword = useSetPassword(userId);

  const onSubmit = async (data: FormValue) => {
    if (passwordStrength(data.password) < 60) {
      enqueueSnackbar(t('ftp_account_password_error'), {
        key: 'passwordStrengthError',
        variant: 'error',
      });
      return;
    }
    await setPassword.mutateAsync({
      password: data.password,
      token: data.token,
    });

    axios.defaults.headers.common.Authorization = false;
    cookie.remove('user_session');

    navigate(`/login?email=${encodeURIComponent(data.email)}`);
  };

  return (
    <StyledContainer maxWidth="xs" className={classes.container}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack alignContent="center" alignItems="center" spacing={3.5}>
            <Box alignItems="center">
              <Typography textAlign="center" variant="h2">
                {t('login.title')}
              </Typography>
              <Typography textAlign="center" variant="body2" color="grayText">
                {t('login.description')}
              </Typography>
            </Box>
            <Divider sx={{ width: '100%' }} />
            <Typography variant="body2">
              <Trans i18nKey="reset_password.instructions" values={{ siteName: 'site name' }} />
            </Typography>
            <Stack spacing={3} sx={{ width: '85%' }}>
              <TextField
                name="password"
                disabled={setPassword.isPending}
                fullWidth
                label={t('password')}
                type="password"
                rules={{ required: true }}
                sx={{ backgroundColor: 'white' }}
              />
              <TextField
                name="verifyPassword"
                disabled={setPassword.isPending}
                fullWidth
                label={t('reset_password.confirm_password')}
                type="password"
                rules={{
                  required: true,
                  validate: (val: string) => {
                    if (watch('password') !== val) {
                      return 'Your passwords do no match';
                    }
                  },
                }}
                sx={{ backgroundColor: 'white' }}
              />
            </Stack>
            <Button
              color="primary"
              variant="contained"
              disabled={setPassword.isPending}
              type="submit"
              sx={{ paddingLeft: '40px', paddingRight: '40px' }}
            >
              {t('reset_password.button')}
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </StyledContainer>
  );
}
